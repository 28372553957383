import { styled, Box } from "@mui/material";
import { IgnitionIcon } from "assets/icons";

export const ObjectMapContainer = styled(Box)({
  width: "100%",
  height: "75vh"
});

export const ObjectMapWrapper = styled(Box)({
  position: "relative"
});

export const RadiusSelectionBox = styled(Box)({
  backgroundColor: "#ffffff",
  padding: "12px 16px",
  borderRadius: "6px",
  width: "320px"
});

export const RadiusSelectionItemsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%"
});

export const SelectRadiusTextBox = styled(Box)({
  color: "#76828F",
  fontSize: "14px"
});

export const AddIconWrapper = styled(Box)({
  position: "absolute",
  bottom: "20px",
  left: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%"
});

export const PageLoaderCentered = styled(Box)<{
  isDrawerCollapsed: boolean;
}>(({ theme, isDrawerCollapsed }) => ({
  ".css-1tlbauj": {
    width: `calc(100% - ${isDrawerCollapsed ? "65px" : "185px"})`
  },

  [theme.breakpoints.down("sm")]: {
    ".css-1tlbauj": {
      width: "100%"
    }
  }
}));

export const IgnitionIconStyled = styled(IgnitionIcon)<{ color?: boolean }>(
  ({ color }) => ({
    "& path": {
      fill: color
    }
  })
);

export const ChildFormBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: "32px"
  }
}));

export const LocationTitle = styled(Box)({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "105%",
  letterSpacing: "-0.01em",
  color: "#1D1F2B",
  paddingBottom: 8,
  borderBottom: "1px solid #EFF4FF"
});

export const RecordsWrapper = styled(Box)({
  display: "flex",
  borderBottom: "1px solid #EFF4FF",
  paddingBottom: 8,
  "&:hover": {
    cursor: "pointer"
  }
});

export const RecordKey = styled(Box)({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "112%",
  color: "#76828F",
  textTransform: "capitalize"
});

export const RecordValue = styled(Box)({
  fontWeight: 300,
  fontSize: 14,
  lineHeight: "116%",
  color: "#1D1F2B",
  marginLeft: "200px"
});
