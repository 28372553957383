import * as Yup from "yup";
import { isEmpty, omit } from "lodash";
import { helpers } from "@vilocnv/allsetra-core";
import { Phone } from "@mui/icons-material";
import { separateCountryCode } from "./activationPortalHelpers";
import { cityRegex, phoneRegExp, postCodeRegex } from "./commonHelpers";

export const transformAccountsForDrawer = (allAccounts: any) => {
  return (
    allAccounts?.length &&
    allAccounts?.map((account: any) => {
      return {
        id: account.uniqueId,
        name: account.name,
        ...(account.children?.length
          ? { children: transformAccountsForDrawer(account?.children) }
          : null)
      };
    })
  );
};

//
// Account Settings Helpers
//
export const accountSettingsFormatterForForm = (account: any) => {
  if (isEmpty(account)) return {};

  const removedUnwantedKeys = omit(account, [
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "isDeleted",
    "lastUpdated",
    "status",
    "updatedBy",
    "owner",
    "devices",
    "usersCount",
    "objectsCount",
    "linkedObjects",
    "countries",
    "accountOwner",
    "alarmsConfiguration"
  ]);

  const transformAddressObjectForForm = (address: any) => {
    if (isEmpty(address)) return null;

    const removedUnwantedKeys = omit(address, [
      "country",
      "created",
      "createdBy",
      "deleted",
      "deletedBy",
      "isDeleted",
      "lastUpdated",
      "updatedBy"
    ]);

    const { countryCode, phone } = separateCountryCode(address?.phoneNumber);

    return {
      ...removedUnwantedKeys,
      countryId: !isEmpty(address.country) ? address.country.id : null,
      phoneNumber: phone,
      countryCode
    };
  };

  const formattedObject = {
    ...removedUnwantedKeys,
    accountIndustry: account.accountIndustry?.id,
    accountType: account.accountType?.id,
    accountOwnerUniqueId: account.accountOwner,
    workingHours: {
      workingHoursSchedule: account.workingHours
    },
    billingAddress: transformAddressObjectForForm(account.billingAddress),
    shippingAddress: transformAddressObjectForForm(account.shippingAddress),
    visitingAddress: transformAddressObjectForForm(account.visitingAddress)
  };

  return formattedObject;
};

export const visitingAddressValidationSchema = (translator: any) =>
  Yup.object().shape({
    id: Yup.number(),
    street: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9\s\-]+$/,
        translator("accountSettings.accountName.invalidCharacters", {
          ns: "formFieldsTranslation"
        })
      )
      .max(
        100,
        translator("accountSettings.accountName.maxLimit", {
          ns: "formFieldsTranslation"
        })
      )
      .nullable()
      .label("Street"),
    houseNumber: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        /^\d+[A-Z]?$/,
        translator("accountSettings.houseNumber.startWithDigits", {
          ns: "formFieldsTranslation"
        })
      )
      .max(
        10,
        translator("accountSettings.houseNumber.maxLimit", {
          ns: "formFieldsTranslation"
        })
      )
      .label("House number"),
    extension: Yup.string()
      .max(
        10,
        translator("accountSettings.extension.maxLimit", {
          ns: "formFieldsTranslation"
        })
      )
      .nullable()
      .label("Extension"),
    city: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        cityRegex,
        translator("accountSettings.city.invalidCharacters", {
          ns: "formFieldsTranslation"
        })
      )
      .max(
        100,
        translator("accountSettings.city.maxLimit", {
          ns: "formFieldsTranslation"
        })
      )
      .label("City"),
    state: Yup.string().nullable().label("State"),
    postalCode: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        postCodeRegex,
        translator("accountSettings.postalCode.mustContain", {
          ns: "formFieldsTranslation"
        })
      )
      .label("Postal code"),
    phoneNumber: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        phoneRegExp,
        translator("accountSettings.phoneNumber.invalidFormat", {
          ns: "formFieldsTranslation"
        })
      )
      .label("Phone number"),
    email: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        translator("accountSettings.email.invalidFormat", {
          ns: "formFieldsTranslation"
        })
      )
      .label("Email"),
    countryId: Yup.number().nullable().label("Country")
  });

export const accountSettingsValidationSchema = (translator: any) => {
  return Yup.object().shape({
    uniqueId: Yup.string(),
    name: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        /^(?! )[a-zA-Z0-9&\-.']+( (?! )[a-zA-Z0-9&\-.']+)*$/,
        translator("accountSettings.accountName.invalidCharacters", {
          ns: "formFieldsTranslation"
        })
      )
      .max(
        100,
        translator("accountSettings.accountName.maxLimit", {
          ns: "formFieldsTranslation"
        })
      )
      .label("Name"),
    debtorNumber: Yup.string().nullable().label("Debtor number"),
    resellerReference: Yup.string().nullable().label("Reseller Reference"),
    customerType: Yup.string().nullable().label("Customer Type"),
    website: Yup.string()
      .nullable()
      .label("Website")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(https?:\/\/)?([\w-]+)\.([a-zA-Z]{2,63})([\/\w.-]*)*\/?$/,
        translator("accountSettings.website.startWithHttp", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        /^[^\s]*$/,
        translator("accountSettings.website.noSpaces", {
          ns: "formFieldsTranslation"
        })
      )
      .url(
        translator("accountSettings.website.invalidURL", {
          ns: "formFieldsTranslation"
        })
      ),
    phoneNumber: Yup.string().nullable().label("Phone number"),
    kvkcocNumber: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        /^\d{8}$/,
        translator("accountSettings.kvkcocNumber.onlyDigits", {
          ns: "formFieldsTranslation",
          max: 8
        })
      )
      .min(
        8,
        translator("accountSettings.kvkcocNumber.maxLimit", {
          ns: "formFieldsTranslation",
          max: 8
        })
      )
      .max(
        8,
        translator("accountSettings.kvkcocNumber.maxLimit", {
          ns: "formFieldsTranslation",
          max: 8
        })
      )
      .nullable()
      .label("KVK number"),
    description: Yup.string().nullable().label("Description"),
    workingHoursType: Yup.string().label("Working Hours Type"),
    accountNumber: Yup.string().nullable().label("Account number"),
    accountType: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .nullable(),
    accountIndustry: Yup.string().nullable(),
    boboid: Yup.string().nullable().label("BOBO ID"),
    notes: Yup.string().nullable().label("Notes"),
    accountOwner: Yup.string().nullable().label("Account owner"),
    multiViewerId: Yup.string().nullable().label("Multi-viewer ID"),
    afasDebitNumber: Yup.string().nullable().label("AFAS Debit number"),
    billingAddress: visitingAddressValidationSchema(translator),
    bankAccount: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        /^NL\d{2}[A-Z]{4}\d{10}$/,
        translator("accountSettings.bankAccount.invalidCharacters", {
          ns: "formFieldsTranslation"
        })
      )
      .label("AFAS Debit number"),
    vatNumber: Yup.string()
      .required(
        translator("accountSettings.fieldRequired.error", {
          ns: "formFieldsTranslation"
        })
      )
      .matches(
        /^NL\d{9}B\d{2}$/,
        translator("accountSettings.vatNumber.invalidCharacters", {
          ns: "formFieldsTranslation"
        })
      )
      .label("VAT number"),
    vatShifted: Yup.string().nullable().label("VAT shifted"),
    paymentTermInDays: Yup.string().nullable().label("Payment term"),
    paymentMethod: Yup.string().nullable().label("Payment method"),
    visitingAddress: visitingAddressValidationSchema(translator),
    shippingAddress: visitingAddressValidationSchema(translator),
    workingHours: Yup.object({
      workingHoursSchedule: helpers.workingHoursValidationSchema
    })
  });
};

export const formatDataforPayload = (values: any) => {
  const formatPhoneNumber = (address: {
    countryCode: string;
    phoneNumber: string;
  }) => {
    const completePhoneNumber = `${address.countryCode}${address.phoneNumber}`;

    return omit(
      {
        ...address,
        phoneNumber: completePhoneNumber
      },
      ["countryCode"]
    );
  };

  const updatedData = {
    ...values,
    visitingAddress: formatPhoneNumber(values.visitingAddress),
    shippingAddress: formatPhoneNumber(values.shippingAddress),
    billingAddress: formatPhoneNumber(values.billingAddress)
  };

  return updatedData;
};
