import { FC } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { types, ObjectMetadataTags } from "@vilocnv/allsetra-core";
import { CardContainer, SmallText } from "./ObjectListingCard.styled";
import { AccuracyTargetIcon, WifiIconPurple } from "assets/icons";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import { selectSpecificObjectState } from "app/data/selectors";

export interface ObjectDetailsCardProps {
  object: types.IObject;
  onObjectCardClick: any;
}

const ObjectListingCard: FC<ObjectDetailsCardProps> = ({
  object,
  onObjectCardClick
}) => {
  const { activeObjectId } = useAppSelector(selectSpecificObjectState);
  const { t } = useTranslation();

  return (
    <CardContainer
      isActive={activeObjectId === object?.uniqueId}
      onClick={() => onObjectCardClick(object.uniqueId)}
    >
      <Stack direction={"column"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box sx={{ width: "25px" }}>
              {/* @ts-ignore */}
              <img style={{ width: "100%" }} src={object?.icon?.url} />
            </Box>
            <Typography sx={{ fontWeight: 600 }}>
              {object?.name || ""}
            </Typography>
          </Stack>
          <Typography sx={{ fontSize: "12px" }}>ID {object.aNumber}</Typography>
        </Stack>

        <Box>
          <Typography sx={{ fontSize: "14px", color: "#323946", mt: 0.5 }}>
            {object.location?.resolvedAddress ?? ""}
          </Typography>
        </Box>
      </Stack>
    </CardContainer>
  );
};

export default ObjectListingCard;
