import { FC } from "react";
import ObjectTabsLayout from "components/common/objects/ObjectTabsLayout/ObjectTabsLayout";

// Data
import { useAppSelector } from "hooks";
import { selectQueriedObjectsState } from "app/data/selectors";
import ObjectRidesSection from "components/sections/objects/ObjectRidesSection/ObjectRidesSection";

const ObjectRides: FC = () => {
  const { specificObject } = useAppSelector(selectQueriedObjectsState);

  return (
    <main>
      <ObjectTabsLayout>
        <ObjectRidesSection specificObject={specificObject} />
      </ObjectTabsLayout>
    </main>
  );
};

export default ObjectRides;
