import React, { FC } from "react";
import { Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getSideMenuObjectsByQueryThunk,
  setSideMenuObjectsPageNumber
} from "app/features";
import { selectQueriedObjectsState } from "app/data/selectors";
import ObjectListingCard from "components/cards/ObjectLIstingCard/ObjectListingCard";

import { Skeleton } from "./Object.styled";
import { useMapState } from "components/maps/Map/v2/GoogleMap/MapStateContext";

interface MapListingProps {
  drawerSelectedAccountId: string;
  setMapZoom: any;
  setMapPanTo: any;
  mapFilters: any;
}

const ObjectsMapListing: FC<MapListingProps> = ({
  drawerSelectedAccountId,
  setMapZoom,
  setMapPanTo,
  mapFilters
}) => {
  const dispatch = useAppDispatch();
  const { onObjectChange } = useMapState();

  const { sideMenuObjects, sideMenuObjectsLoading, totalSideMenuRecords } =
    useAppSelector(selectQueriedObjectsState);

  const loadMoreObjects = () => {
    //@ts-ignore
    if (sideMenuObjects.length < totalSideMenuRecords) {
      const nextPage = Math.ceil(sideMenuObjects.length / 25) + 1;

      dispatch(
        getSideMenuObjectsByQueryThunk({
          accountId: drawerSelectedAccountId,
          params: {
            itemsPerPage: 25,
            page: nextPage,
            where: [{ field: "name", value: "", type: 0 }]
          }
        })
      );

      dispatch(setSideMenuObjectsPageNumber(nextPage));
    }
  };

  return (
    <>
      {!sideMenuObjects.length && !sideMenuObjectsLoading ? (
        <Box sx={{ textAlign: "center", fontWeight: 700 }}>
          No Objects available
        </Box>
      ) : (
        <InfiniteScroll
          dataLength={sideMenuObjects.length}
          next={loadMoreObjects}
          //@ts-ignore
          hasMore={sideMenuObjects.length < totalSideMenuRecords}
          loader={
            <Box sx={{ textAlign: "center", p: 1.5 }}>
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
            </Box>
          }
          scrollThreshold={0.9}
          endMessage={
            <Box sx={{ textAlign: "center", py: 3, fontWeight: 500 }}>
              No more records to load
            </Box>
          }
          scrollableTarget="scrollableDiv"
        >
          {sideMenuObjects.map((object) => (
            <ObjectListingCard
              key={object.uniqueId}
              object={object}
              onObjectCardClick={onObjectChange}
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default ObjectsMapListing;
