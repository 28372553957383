import { FC, useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Topbar,
  Table,
  useDispatchOnParams,
  MapFilterForm,
  toast,
  types
} from "@vilocnv/allsetra-core";
import { useNavigate } from "react-router-dom";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { ALL_OBJECTS_TABLE_COLUMNS } from "app/data/constants";
import {
  getAllAccountGroupsThunk,
  getAllKeysByAccountThunk,
  getObjectsByQueryThunk,
  getObjectsLocationsThunk,
  getObjectTypesByAccountThunk,
  getSideMenuObjectsByQueryThunk,
  resetAllObjects,
  resetSideMenuObjects,
  setActiveObjectId,
  setFilters,
  setObjectActiveTabIndex,
  setObjectsListingView,
  setSpecificObject
} from "app/features";
import {
  selectAccountGroups,
  selectAccountKeysState,
  selectDrawerSelectedAccountId,
  selectMapFilterState,
  selectObjectsState,
  selectObjectTypesState,
  selectQueriedObjectsState
} from "app/data/selectors";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "app/integrations/microsoftInsights";
import { useTranslation } from "react-i18next";
import { CloseGreenIcon, OpenGreenIcon } from "assets/icons";
import { FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import {
  GridWrapperBox,
  IconStickyBox,
  IconWrapperBox,
  MainWrapperBox,
  ObjectTableBox,
  ScrollableBox
} from "./Object.styled";
import ObjectsMapListing from "./ObjectsMapLIsting";
import { MapStateProvider } from "components/maps/Map/v2/GoogleMap/MapStateContext";
import GoogleMap from "components/maps/Map/v2/GoogleMap";
import DashboardMapTopLeftSection from "components/maps/DashboardMap/DashboardMapTopLeftSection";
import DashboardMapTopRightSection from "components/maps/DashboardMap/DashboardMapTopRightSection";
import TrafficLayer from "components/maps/Map/v2/GoogleMap/TrafficLayer";
import MarkersClusterer from "components/maps/Map/v2/GoogleMap/MarkersClusterer";
import Markers from "components/maps/Map/v2/GoogleMap/Markers";
import Geozone from "components/maps/Map/v2/GoogleMap/Geozone";
import Geozones from "components/maps/Map/v2/GoogleMap/Geozones";

const Objects: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Global States
  const {
    objects,
    totalRecords,
    loading,
    sideMenuObjects,
    sideMenuObjectsLoading,
    objectsListingView,
    sideMenuObjectsPageNumber
  } = useAppSelector(selectQueriedObjectsState);

  const isSideMenuVisible = objectsListingView === "mapListing";

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );
  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );
  const { accountGroups, loading: accountGroupsLoading } =
    useAppSelector(selectAccountGroups);

  const { allObjects, loading: allObjectsLoading } =
    useAppSelector(selectObjectsState);
  const { mapFilters } = useAppSelector(selectMapFilterState);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [mapZoom, setMapZoom] = useState(7);
  const [mapPanTo, setMapPanTo] = useState({});

  const activeAccountKeys = useMemo(() => {
    return Array.isArray(accountKeys)
      ? accountKeys.filter((key) => key.isActive)
      : [];
  }, [accountKeys]);

  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const { t, i18n } = useTranslation([
    "translation",
    "tableHeadingsTranslation"
  ]);

  const tableColumns = useMemo(
    () => ALL_OBJECTS_TABLE_COLUMNS(t, i18n.language),
    [t, i18n.language]
  );

  const mapFiltersSubmitHanlder = async (
    values: types.IMapFilter,
    formikHelpers: FormikHelpers<types.IMapFilter>
  ) => {
    if (!drawerSelectedAccountId) {
      toast.info("Kindly select an account from the navigation drawer.");
      return;
    }

    formikHelpers.setSubmitting(true);
    setSubmitting(true);

    const payload = {
      accountId: drawerSelectedAccountId ?? "",
      values: {
        ...values
      }
    };

    const { type } = await dispatch(getObjectsLocationsThunk(payload));

    if (type === "objects/getObjectsLocationsThunk/fulfilled") {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
      setFilterOpen(false);
      dispatch(setFilters(values));
    }
  };

  useDispatchOnParams(getObjectsByQueryThunk, {
    args: {
      accountId: drawerSelectedAccountId
    },
    orderBy: [{ field: "name", ascending: true }]
  });

  const rowClickHandler = (row: any) => {
    dispatch(setObjectActiveTabIndex(0));
    dispatch(setSpecificObject(null));
    dispatch(setActiveObjectId(row?.uniqueId));
    navigate({
      pathname: `/dashboard/objects/details/${row.uniqueId}`
    });
  };

  useEffect(() => {
    if (!isEmpty(drawerSelectedAccountId)) {
      dispatch(
        getSideMenuObjectsByQueryThunk({
          accountId: drawerSelectedAccountId,
          params: {
            itemsPerPage: 25,
            page: sideMenuObjectsPageNumber,
            where: [{ field: "name", value: "", type: 0 }]
          }
        })
      );

      dispatch(
        getObjectsLocationsThunk({
          accountId: drawerSelectedAccountId,
          values: mapFilters
        })
      );

      dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId || ""));
      dispatch(getObjectTypesByAccountThunk(drawerSelectedAccountId || ""));
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId || ""));
    }

    return () => {
      dispatch(setActiveObjectId(null));
      dispatch(resetAllObjects());
      dispatch(resetSideMenuObjects());
    };
  }, [drawerSelectedAccountId]);

  return (
    <MapStateProvider
      objects={allObjects}
      isLoading={allObjectsLoading}
      disableNavigator={true}
      skipCurrentLocation={true}
      showSearch={true}
      showFilter={true}
      isDashboardMap={true}
      objectsMarker={false}
      mapZoom={mapZoom}
      mapPanTo={mapPanTo}
    >
      <MainWrapperBox>
        <GridWrapperBox isSideMenuVisible={isSideMenuVisible}>
          <ScrollableBox id="scrollableDiv">
            <IconStickyBox>
              <IconWrapperBox isSideMenuVisible={isSideMenuVisible}>
                {isSideMenuVisible ? (
                  <OpenGreenIcon
                    onClick={() =>
                      dispatch(setObjectsListingView("tableListing"))
                    }
                  />
                ) : (
                  <CloseGreenIcon
                    onClick={() =>
                      dispatch(setObjectsListingView("mapListing"))
                    }
                  />
                )}
              </IconWrapperBox>
            </IconStickyBox>
            <Topbar
              theme={theme}
              title={isSideMenuVisible ? "" : t("drawerMenuLinks.objects")}
            />

            {isSideMenuVisible ? (
              <>
                <ObjectsMapListing
                  drawerSelectedAccountId={drawerSelectedAccountId}
                  setMapZoom={setMapZoom}
                  setMapPanTo={setMapPanTo}
                  mapFilters={mapFilters}
                />
              </>
            ) : (
              <ObjectTableBox mx={4}>
                <Table
                  columns={tableColumns}
                  data={objects}
                  progressPending={loading}
                  paginationTotalRows={totalRecords}
                  searchPlaceholder={t("common.search")}
                  onRowClicked={rowClickHandler}
                  sortServer
                />
              </ObjectTableBox>
            )}
          </ScrollableBox>
          {isSideMenuVisible ? (
            <Box>
              <DashboardMapTopLeftSection />
              <GoogleMap>
                <DashboardMapTopRightSection />
                <TrafficLayer />
                <MarkersClusterer />
                <Markers />
                <Geozone />
                <Geozones />
              </GoogleMap>
            </Box>
          ) : (
            <></>
          )}
        </GridWrapperBox>

        <MapFilterForm
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={mapFiltersSubmitHanlder}
          groups={accountGroups}
          types={objectTypes}
          keys={activeAccountKeys}
          dataLoading={
            accountKeysLoading || objectTypesLoading || accountGroupsLoading
          }
          theme={theme}
          initialValues={mapFilters}
          translator={t}
          submitting={submitting}
        />
      </MainWrapperBox>
    </MapStateProvider>
  );
};

export default withAITracking(reactPlugin, Objects);
