import { styled, Box, Typography, Stack } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

export const CardContainer = styled(Box)<{
  isActive?: boolean;
}>(({ isActive }) => ({
  padding: "20px",
  borderBottom: "1px solid #DFE5F4",
  background: "#fff",
  cursor: "pointer",
  backgroundColor: isActive ? "#F5FFFE" : "#FFF",
  "&:hover": {
    backgroundColor: "#F5FFFE"
  }
}));

export const SmallText = styled(Typography)<{
  color?: string;
}>(({ color }) => ({
  color: color || "#646D7B",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "132%"
}));

export const InfoIcon = styled(InfoRoundedIcon)({
  width: "16px",
  height: "16px",
  color: "#959EB2"
});

export const ObjectDetailsLeftContainer = styled(Stack)(({ theme }) => ({
  maxHeight: "75vh",
  overflowY: "scroll",
  scrollBehavior: "smooth",

  ["::-webkit-scrollbar"]: {
    width: 0,
    background: "transparent"
  },

  [theme.breakpoints.down("md")]: {
    maxHeight: "none",
    overflowY: "hidden",
    marginBottom: "20px"
  }
}));
