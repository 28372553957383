import { FC } from "react";
import { PageLoader } from "@vilocnv/allsetra-core";
import ObjectSettingsForm from "components/forms/objects/ObjectSettingsForm/ObjectSettingsForm";

// DATA
import { useActiveObjectById, useAppSelector } from "hooks";
import { PageLoaderCentered } from "components/sections/objects/ObjectDetailsSection/ObjectDetailsSection.styled";
import { selectIsDrawerCollapsed } from "app/data/selectors";

const ObjectSettings: FC = () => {
  const { specificObject, loading } = useActiveObjectById();

  // Global State
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);

  return (
    <div>
      {loading ? (
        <PageLoaderCentered isDrawerCollapsed={isDrawerCollapsed}>
          <PageLoader />
        </PageLoaderCentered>
      ) : (
        <ObjectSettingsForm activeObject={specificObject} />
      )}
    </div>
  );
};

export default ObjectSettings;
