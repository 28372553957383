import { FC, Fragment } from "react";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { ObjectInfo } from "./MultipleRidesGoogleMap.styled";
import { MarkerBlip } from "../Map/Map.styled";
import { MarkerF, OverlayViewF, OverlayView } from "@react-google-maps/api";

export interface MultipleRidesMapMarkersProps {
  singleItem: any;
  mainItem: any;
  handleMarkerClick: (marker: any, mainItem: any, singleItem: any) => void;
  showObjectName?: boolean;
}

const MultipleRidesMapMarkers: FC<MultipleRidesMapMarkersProps> = ({
  singleItem,
  mainItem,
  handleMarkerClick,
  showObjectName
}) => {
  if (isEmpty(singleItem)) return null;

  const getPixelPositionOffset = () => ({
    x: 20,
    y: -29
  });

  const getFormattedDate = (date: string) =>
    date ? DateTime.fromISO(date).toFormat("MMM dd, HH:mm") : "N/A";

  const startMarker = singleItem[0];
  const endMarker = singleItem[singleItem.length - 1];

  const getRideIcon = (iconUrl: string, iconPosition: string) => {
    const splitedIcon = iconUrl.split(".png")[0];
    const iconsColor = iconPosition === "start" ? "green" : "red";

    return `${splitedIcon}-${iconsColor}.png`;
  };

  return (
    <Fragment>
      <MarkerF
        onClick={() => handleMarkerClick(startMarker, mainItem, singleItem)}
        position={{
          lat: startMarker?.latitude ?? 0,
          lng: startMarker?.longitude ?? 0
        }}
        icon={{
          url: getRideIcon(mainItem.objectIcon, "start"),
          scaledSize: new window.google.maps.Size(34, 34)
        }}
      />
      <MarkerF
        onClick={() => handleMarkerClick(endMarker, mainItem, singleItem)}
        position={{
          lat: endMarker?.latitude ?? 0,
          lng: endMarker?.longitude ?? 0
        }}
        icon={{
          url: getRideIcon(mainItem.objectIcon, "end"),
          scaledSize: new window.google.maps.Size(34, 34)
        }}
      />

      {showObjectName && (
        <Fragment>
          <OverlayViewF
            position={{
              lat: startMarker.latitude,
              lng: startMarker.longitude
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <Fragment>
              <ObjectInfo>{`${mainItem?.objectName} ${getFormattedDate(startMarker.date)}`}</ObjectInfo>
              <MarkerBlip></MarkerBlip>
            </Fragment>
          </OverlayViewF>
          <OverlayViewF
            position={{
              lat: endMarker.latitude,
              lng: endMarker.longitude
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <Fragment>
              <ObjectInfo>{`${mainItem?.objectName} ${getFormattedDate(endMarker.date)}`}</ObjectInfo>
              <MarkerBlip></MarkerBlip>
            </Fragment>
          </OverlayViewF>
        </Fragment>
      )}
    </Fragment>
  );
};

export default MultipleRidesMapMarkers;
