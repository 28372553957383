import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect
} from "react";
import {
  useAppSelector,
  useAppDispatch,
  useMap,
  useObjectLiveLocation
} from "hooks";
import {
  selectDrawerSelectedAccountId,
  selectGeozonesState,
  selectObjectMapState,
  selectSpecificObjectState
} from "app/data/selectors";
import {
  getAllGeozonesThunk,
  getObjectDataForMapThunk,
  setActiveObjectId
} from "app/features";
import { DEFAULT_COORDINATES } from "app/data/constants";
import { isEmpty } from "lodash";
import { MapProps, MapStateContextProps, MarkerData } from "./types";

const MapStateContext = createContext<MapStateContextProps | undefined>(
  undefined
);

export const MapStateProvider: React.FC<MapProps> = ({
  children,
  center = DEFAULT_COORDINATES,
  zoom = 8,
  height = "100vh",
  objects = [],
  disableNavigator = true,
  isDashboardMap = false,
  objectsMarker = false,
  showSearch = false,
  showFilter = false,
  geozones,
  radius,
  isLoading = false
}) => {
  const { mapRef, isLoaded, loadError, centerCoords, onMapLoad } = useMap({
    center,
    disableNavigator
  });
  const { allGeozones } = useAppSelector(selectGeozonesState);

  const { markers } = useAppSelector(selectObjectMapState);
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const dispatch = useAppDispatch();

  const [selectedCluster, setSelectedCluster] = useState<any>(null);
  const [showSatelliteMode, setShowSatelliteMode] = useState(false);
  const [showClusters, setShowClusters] = useState(true);
  const [showTraffic, setShowTraffic] = useState(false);
  const [showObjectNames, setShowObjectNames] = useState(false);
  const [showGeozones, setShowGeozones] = useState<boolean>(true);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoadingTiles, setIsLoadingTiles] = useState(false);
  const { activeObjectId } = useAppSelector(selectSpecificObjectState);

  useObjectLiveLocation(isSelectOpen);

  useEffect(() => {
    window.localStorage.setItem("selectedObjectId", activeObjectId ?? "");
  }, [activeObjectId]);

  useEffect(() => {
    if (activeObjectId) handleMarkerClick(activeObjectId);
  }, [activeObjectId]);

  useObjectLiveLocation(isSelectOpen);

  useEffect(() => {
    if (mapRef && drawerSelectedAccountId) {
      mapRef?.setZoom(7);
      if (isDashboardMap) {
        setShowGeozones(true);
        handleShowGeozoneClick();
      }
    }
  }, [drawerSelectedAccountId, mapRef]);

  const handleShowGeozoneClick = useCallback(() => {
    if (drawerSelectedAccountId) {
      dispatch(getAllGeozonesThunk(drawerSelectedAccountId));
    }
  }, [dispatch, drawerSelectedAccountId]);

  const onObjectChange = (value: any) => {
    if (isEmpty(value)) return;
    dispatch(setActiveObjectId(null));
    setIsLoadingTiles(true);

    const objectIndex = objects.findIndex((obj: any) => obj.uniqueId === value);
    if (objectIndex < 0) return;

    mapRef?.setZoom(14);
    mapRef?.panTo({
      lat: objects[objectIndex].location.latitude,
      lng: objects[objectIndex].location.longitude
    });
    setTimeout(() => {
      handleMarkerClick(objects[objectIndex].uniqueId);
      dispatch(setActiveObjectId(value || ""));
      setIsLoadingTiles(false);
    }, 1000);
  };

  const onTilesLoaded = () => {
    setIsLoadingTiles(false);
  };

  const handleEnableClusterClick = useCallback(() => {
    setShowClusters((prev) => !prev);
  }, []);

  const handleClusterClick = (cluster: any) => {
    dispatch(setActiveObjectId(null));
    setSelectedCluster(cluster);
  };

  const handleClusterCloseInfoWindow = () => {
    dispatch(setActiveObjectId(null));
    setSelectedCluster(null);
  };

  const handleMarkerClick = useCallback(
    async (markerId: string) => {
      if (isEmpty(markerId)) return;

      window.localStorage.setItem("selectedObjectId", markerId);

      await dispatch(
        getObjectDataForMapThunk({
          accountId: drawerSelectedAccountId || "",
          objectId: markerId
        })
      );
    },
    [dispatch, drawerSelectedAccountId]
  );

  const shouldRenderGeozones = useMemo(
    () => zoom > 10 && showGeozones,
    [zoom, showGeozones]
  );

  return (
    <MapStateContext.Provider
      value={{
        markers,
        showSatelliteMode,
        setShowSatelliteMode,
        showClusters,
        setShowClusters,
        showTraffic,
        setShowTraffic,
        showObjectNames,
        setShowObjectNames,
        showGeozones,
        setShowGeozones,
        handleEnableClusterClick,
        handleShowGeozoneClick,
        isSelectOpen,
        setIsSelectOpen,
        mapRef,
        isLoaded,
        isLoading,
        isLoadingTiles,
        loadError,
        centerCoords,
        onMapLoad,
        onObjectChange,
        onTilesLoaded,
        zoom,
        height,
        selectedCluster,
        handleClusterClick,
        handleClusterCloseInfoWindow,
        handleMarkerClick,
        shouldRenderGeozones,
        allGeozones,
        objectsMarker,
        isDashboardMap,
        showSearch,
        showFilter,
        geozones,
        radius,
        drawerSelectedAccountId,
        activeObjectId
      }}
    >
      {children}
    </MapStateContext.Provider>
  );
};

export const useMapState = () => {
  const context = useContext(MapStateContext);
  if (!context) {
    throw new Error("useMapState must be used within a MapStateProvider");
  }
  return context;
};
