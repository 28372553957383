import { Box, styled } from "@mui/material";

export const ObjectInfo = styled(Box)({
  backgroundColor: "#1D1F2BB8",
  color: "#fff",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14.4px",
  padding: "8px",
  borderRadius: "4px",
  marginLeft: 10
});

export const MultipleRidesMapContainer = styled(Box)({
  height: "calc(100vh - 170px)",
  width: "100%",
  position: "relative",

  ".gm-style-iw.gm-style-iw-c": {
    background: "none",
    boxShadow: "none"
  },
  "button.gm-ui-hover-effect": {
    top: "6px !important",
    "& span": {
      backgroundColor: "#ffffff !important"
    }
  },
  ".gm-style-iw-d": {
    overflow: "hidden !important"
  },
  ".gm-style .gm-style-iw-tc::after": {
    background: "#192026E0"
  },
  ".gmnoprint .gm-style-mtc": {
    display: "none"
  },
  "button.gm-control-active.gm-fullscreen-control": {
    display: "none"
  }
});
