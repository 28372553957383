import { FC, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import {
  accountFormInitialValues,
  accountFormSchema,
  europeanCountryCodes,
  informationInitialValues,
  informationValidationSchema
} from "app/data/helpers/activationPortalHelpers";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import InnerForm from "./children/InnerForm";
import AssociatedAccountForm from "./children/AssociatedAccountForm";

//Data
import { useAppDispatch, useAppSelector } from "hooks";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";
import { PageLoader, useDispatchOnParams } from "@vilocnv/allsetra-core";
import {
  getInformationSecurityQuestionsThunk,
  setSubscribeFormData,
  getAllUserAccountsThunk,
  getCountryListThunk
} from "app/features";
import { IActivationInformation } from "app/data/types";
import { isEmpty } from "lodash";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { selectSettingsState } from "app/data/selectors";

interface Props {
  gotoNextStep: () => void;
}

const ActivationInformationForm: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  const { countries, countryListLoading } = useAppSelector(selectSettingsState);

  useEffect(() => {
    dispatch(getCountryListThunk());
  }, []);

  //Global States
  const {
    securityQuestions,
    loading,
    subscribeFormData,
    myAccounts,
    fromDashboard
  } = useAppSelector(selectActivationPortalState);

  // Local State
  const [tabSelectedIndex, setTabSelectedIndex] = useState(
    subscribeFormData?.tabSelectedIndex || 0
  );

  const { t } = useTranslation([
    "translation",
    "activationPortal",
    "formFieldsTranslation"
  ]);

  useDispatchOnParams(
    fromDashboard
      ? getAllUserAccountsThunk
      : getInformationSecurityQuestionsThunk
  );

  const onSubmitHandler = (values: IActivationInformation) => {
    const _values: any = { ...values };
    if (values?.companyInfo?.name === "-") {
      _values.companyInfo.name = "";
    }
    dispatch(setSubscribeFormData({ ..._values, tabSelectedIndex }));
    gotoNextStep();
  };

  const formValues = useMemo(() => {
    if (fromDashboard)
      return !isEmpty(subscribeFormData)
        ? subscribeFormData
        : accountFormInitialValues;
    else {
      return !isEmpty(subscribeFormData) && subscribeFormData?.userInfo
        ? subscribeFormData
        : informationInitialValues;
    }
  }, [subscribeFormData]);

  return (
    <Formik
      initialValues={formValues}
      onSubmit={onSubmitHandler}
      validationSchema={
        fromDashboard ? accountFormSchema(t) : informationValidationSchema(t)
      }
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid, setFieldValue }) => (
        <Form>
          {loading ? (
            <PageLoader />
          ) : (
            <StepScreenLayout
              title={t("titles.information", {
                ns: "activationPortal"
              })}
              subtitle={t("descriptions.information", {
                ns: "activationPortal"
              })}
              disabled={!isValid}
              onButtonSubmit={handleSubmit}
            >
              <AuthenticatedTemplate>
                <AssociatedAccountForm data={myAccounts} translator={t} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <InnerForm
                  countries={countries}
                  countryListLoading={countryListLoading}
                  securityQuestions={securityQuestions}
                  tabSelectedIndex={tabSelectedIndex}
                  setTabSelectedIndex={setTabSelectedIndex}
                  translator={t}
                  setFieldValue={setFieldValue}
                  europeanCountryCodes={europeanCountryCodes}
                />
              </UnauthenticatedTemplate>
            </StepScreenLayout>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ActivationInformationForm;
