import { FC, Fragment } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  Button,
  FormikInputField,
  FormikSelectField,
  TwoColsLayout
} from "@vilocnv/allsetra-core";
import AddIcon from "@mui/icons-material/Add";
import PhoneInput from "components/ui/inputs/PhoneInput/PhoneInput";

const AlarmContactPersonsForm: FC = ({
  form,
  push,
  securityQuestions,
  translator,
  remove
}: any) => {
  const theme = useTheme();

  const handleAddContactPerson = () => {
    push({
      firstName: "",
      lastName: "",
      phoneNumber1: "",
      countryCode1: "+31",
      phoneNumber2: "",
      countryCode2: "+31",
      email: "",
      safeQuestionId: "",
      safeQuestionAnswer: "",
      prefix: ""
    });
  };

  const handleDeleteContactPerson = (index: number) => {
    remove(index);
  };

  return (
    <Stack spacing={3}>
      {form.values.alarmPersons?.map((_: any, index: number) => (
        <Fragment key={index}>
          <h4>{`Alarm Contact Person ${index + 1}`}</h4>
          <TwoColsLayout hideDivider>
            <FormikSelectField
              theme={theme}
              label={translator("prefix.label", {
                ns: "formFieldsTranslation"
              })}
              name={`alarmPersons.${index}.prefix`}
              options={[
                { key: 1, value: "Mr." },
                { key: 2, value: "Mrs." }
              ]}
              optionLabelKey="value"
              optionValueKey="value"
              fullWidth
            />
            <FormikInputField
              theme={theme}
              label={translator("firstName.label", {
                ns: "formFieldsTranslation"
              })}
              name={`alarmPersons.${index}.firstName`}
              fullWidth
              required
            />
          </TwoColsLayout>

          <TwoColsLayout hideDivider>
            <FormikInputField
              theme={theme}
              label={translator("lastName.label", {
                ns: "formFieldsTranslation"
              })}
              name={`alarmPersons.${index}.lastName`}
              fullWidth
              required
            />
            <FormikInputField
              theme={theme}
              label={translator("email.label", {
                ns: "formFieldsTranslation"
              })}
              name={`alarmPersons.${index}.email`}
              fullWidth
              required
            />
          </TwoColsLayout>

          <TwoColsLayout hideDivider>
            <PhoneInput
              label={`${translator("phoneNumber.label", {
                ns: "formFieldsTranslation"
              })} 1`}
              translator={translator}
              phoneInputName={`alarmPersons.${index}.phoneNumber1`}
              countryInputName={`alarmPersons.${index}.countryCode1`}
              required
            />
            <PhoneInput
              label={`${translator("phoneNumber.label", {
                ns: "formFieldsTranslation"
              })} 2`}
              translator={translator}
              phoneInputName={`alarmPersons.${index}.phoneNumber2`}
              countryInputName={`alarmPersons.${index}.countryCode2`}
            />
          </TwoColsLayout>

          <TwoColsLayout>
            <FormikSelectField
              theme={theme}
              label={translator("securityQuestion.label", {
                ns: "formFieldsTranslation"
              })}
              name={`alarmPersons.${index}.safeQuestionId`}
              options={securityQuestions}
              optionLabelKey="question"
              optionValueKey="uniqueId"
              required
              fullWidth
            />
            <FormikInputField
              theme={theme}
              label={translator("securityQuestionResponse.label", {
                ns: "formFieldsTranslation"
              })}
              name={`alarmPersons.${index}.safeQuestionAnswer`}
              fullWidth
              required
            />
          </TwoColsLayout>
          <TwoColsLayout>
            <Button
              theme={theme}
              color="error"
              variant="outlined"
              text={translator("headings.deleteContactPerson", {
                ns: "activationPortal"
              })}
              onClick={() => handleDeleteContactPerson(index)}
            />
          </TwoColsLayout>
        </Fragment>
      ))}

      <TwoColsLayout hideDivider>
        <Button
          theme={theme}
          variant="outlined"
          text={translator("headings.addContactPerson", {
            ns: "activationPortal"
          })}
          startIcon={<AddIcon />}
          onClick={handleAddContactPerson}
        />
      </TwoColsLayout>
    </Stack>
  );
};

export default AlarmContactPersonsForm;
