import { styled, Box } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";

export const StepScreenContainer = styled(Box)<{ isFietsgestolen?: boolean }>(
  ({ isFietsgestolen, theme }) => ({
    padding: isFietsgestolen ? "16px 60px 48px 112px" : "72px 60px 48px 112px",
    display: "flex",
    flexDirection: "column",
    gap: "48px",

    [theme.breakpoints.down("md")]: {
      padding: "72px 60px 48px 60px"
    },

    [theme.breakpoints.down("sm")]: {
      padding: "48px 50px 48px 50px"
    }
  })
);

export const StepHeadingContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px"
});

export const StepTitle = styled("p")(({ theme }) => ({
  color: "var(--gray-900, #1D1F2B)",
  fontSize: "40px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "115%",
  letterSpacing: "0.4px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "30px"
  },
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: "24px"
  }
}));

export const StepButtonContainer = styled(Box)<{ isFietsgestolen?: boolean }>(
  ({ isFietsgestolen, theme }) => ({
    display: "flex",
    justifyContent: isFietsgestolen ? "flex-start" : "flex-end",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  })
);
export const StepSubtitle = styled("p")<{ color: string }>(
  ({ color, theme }) => ({
    color,
    fontSize: "18px",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "136%",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  })
);

export const StepScreenButton = styled(Button)<{
  disabled?: boolean;
  isFietsgestolen?: boolean;
}>(({ disabled, theme }) => ({
  display: "flex",
  position: "fixed",
  bottom: "40px",
  right: "60px",
  zIndex: 350,
  width: "312px",
  height: "56px",
  padding: "14px 32px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
  background: disabled ? "#D5D9E8" : theme.palette.primary.main,
  border: disabled
    ? "1px solid #D5D9E8"
    : `1px solid ${theme.palette.primary.main}`,
  color: "#FFF",
  textAlign: "center",
  fontSize: "18px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "115%",
  letterSpacing: "0.18px",
  cursor: disabled ? "default" : "pointer",

  [":hover"]: {
    background: `${disabled ? "#D5D9E8" : theme.palette.primary.main} !important`
  },

  [theme.breakpoints.down("md")]: {
    width: "220px",
    height: "40px",
    padding: "10px 28px",
    fontSize: "14px"
  },

  [theme.breakpoints.down("sm")]: {
    position: "static"
  }
}));
