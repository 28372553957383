import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Marker from "./Marker";
import { type MarkerClusterer, MarkerClustererF } from "@react-google-maps/api";
import MarkerClustererInfoWindow from "./MarkerClustererInfoWindow";
import { useMapState } from "./MapStateContext";

const OPTIONS = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  zoomOnClick: false
};

const MarkersClusterer: FC = () => {
  const clustererRef = useRef<any | null>(null);
  const {
    markers,
    mapRef,
    showClusters,
    selectedCluster,
    handleClusterClick,
    handleClusterCloseInfoWindow,
    isLoading
  } = useMapState();

  const forceRedrawClusters = () => {
    if (clustererRef.current) {
      clustererRef.current.redraw();
    }
  };

  useEffect(() => {
    if ((showClusters && mapRef) || !isLoading) {
      setTimeout(() => {
        forceRedrawClusters();
      }, 1000);
    }
  }, [showClusters, isLoading]);

  const renderMarkers = useMemo(() => {
    return (clusterer: any) => {
      return markers.map((marker, index) => (
        <Marker
          noClustererRedraw={true}
          key={index}
          marker={marker}
          clusterer={clusterer}
        />
      ));
    };
  }, [markers]);

  return (
    <>
      {showClusters && markers.length > 0 && (
        <MarkerClustererF
          onLoad={(clusterer) => (clustererRef.current = clusterer)}
          options={OPTIONS}
          minimumClusterSize={5}
          onClick={handleClusterClick}
          onUnmount={(clusterer) => clusterer.clearMarkers()}
        >
          {(clusterer) => <>{renderMarkers(clusterer)}</>}
        </MarkerClustererF>
      )}

      {selectedCluster && (
        <MarkerClustererInfoWindow
          cluster={selectedCluster}
          markers={markers}
          onClose={handleClusterCloseInfoWindow}
        />
      )}
    </>
  );
};

export default React.memo(MarkersClusterer);
